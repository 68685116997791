import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("仓库编号"),
    dataIndex: "number",
    sorter: true,
  },
  {
    title: T("仓库名称"),
    dataIndex: "name",
    sorter: true,
  },
  {
    title: T("备注"),
    dataIndex: "remark",
    ellipsis: true,
  },
  {
    title: T("状态"),
    dataIndex: "is_active",
    scopedSlots: { customRender: "is_active" },
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "156px",
  },
];
